import { PushNotifications, PermissionStatus } from '@capacitor/push-notifications'

interface PushNotificationPermission extends PermissionStatus {
  isGranted: boolean,
}

interface ICapacitorPushNotification {
  checkPermission: () => Promise<PushNotificationPermission>
  checkOrRequestPermission: () => Promise<PushNotificationPermission>
  addListeners: (
    onRegistrationSuccess: (token: string) => void,
    onActionPerformed: () => void,
    onNotificationReceived: () => void
  ) => void
  register: () => void
  removeAllDeliveredNotifications: () => void
}

export default class CapacitorPushNotification implements ICapacitorPushNotification {
  async checkPermission () {
    const permission = await PushNotifications.checkPermissions()
    return { ...permission, isGranted: permission.receive === 'granted' }
  }

  async checkOrRequestPermission () {
    const permission = await this.checkPermission()
    if (permission.isGranted) { return permission }
    const requestedPermission = await PushNotifications.requestPermissions()
    return { ...requestedPermission, isGranted: requestedPermission.receive === 'granted' }
  }

  async addListeners (
    onRegistrationSuccess: (token: string) => void,
    onActionPerformed: () => void,
    onNotificationReceived: () => void
  ) {
    await PushNotifications.removeAllListeners()
    await PushNotifications.addListener('registration', (registerToken: any) => {
      const token = registerToken?.value
      if (token) {
        onRegistrationSuccess(token)
      }
    })
    await PushNotifications.addListener('registrationError', (error: any) => {
      /* eslint-disable no-console */
      console.error('Registration error: ', error.error)
    })
    await PushNotifications.addListener('pushNotificationActionPerformed', onActionPerformed)
    await PushNotifications.addListener('pushNotificationReceived', onNotificationReceived)
  }

  async register () {
    await PushNotifications.register()
  }

  async removeAllDeliveredNotifications () {
    await PushNotifications.removeAllDeliveredNotifications()
  }
}
